<div class="modal-content">
<div class="modal-header pb-0">
    <h3 class="modal-title" translate>Product Summary</h3>
    <i class="icon icon-Close2 cursor-pointer" (click)="closeModal()">
    </i>
</div>
<div class="modal-body">
    <div class="grid-container root-wrapper">
        <div class="box-container box-view" *ngIf="product;else loader">
            <div class="box-container-inner gap-15">
                <div class="box-item-col gap-63">
                    <div class="img-container d-inline-block align-middle">
                        <img *ngIf="product?.images && product?.images.length > 0"
                            [src]="product?.images[0]" alt="Product Image">
                        <img *ngIf="product?.images && product?.images.length == 0"
                            src="../../../../assets/images/no-image.svg" alt="Product Image">
                    </div>
                    <div class="d-inline-block name-container prod-name-bv">
                        <div class="d-flex">
                            <a [routerLink]="[commonFunctions?.redirectToScorecardInfo(product.product_id,'product',true)?.params]" [queryParams]="commonFunctions?.redirectToScorecardInfo(product.product_id,'product',true)?.queryParams" (click)="[commonFunctions.setRedirectionForScorecard(),ngbModal.dismissAll()]" >
                                <h3 class="mb-0 available-heading cursor-pointer on-hover-action" showMultiTooltipOnTextOverflow
                                    [data]="product?.name">
                                </h3>
                            </a>
                        <a href={{product?.url}} target="_blank" class='navigate-link' placement="auto" ngbTooltip="{{'Go to Product Channel' | translate}}"
                        tooltipClass="info-custom-tooltip"><i class="icon icon-Open-link"></i></a>
                        </div>

                        <div class="showVariants" *ngIf="product?.variant_count">
                            <div ngbDropdown class="d-inline-block" placement="bottom-left"
                                class="note-text available-dropdown">
                                <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">{{'In' | translate}}
                                    {{(product?.variant_count)}} {{'Variants' | translate}}</span>
                            </div>
                        </div>
                        <div class="showVariants emptyVariant" *ngIf="!product?.variant_count"></div>
                        <div>
                            <ng-template #starLabel>
                                <span class="star">&#x02605;</span>
                            </ng-template>
                            <div class="ratingLabel mt-1"
                                [ngClass]="commonFunctions.getStarStatus(product?.rating)"
                                *ngIf="product?.rating">
                                {{product?.rating}}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1">
                                </ngb-rating>
                            </div>
                            <div class="card-view-rating" *ngIf="!product?.rating"></div>
                        </div>
                    </div>
                </div>
                <div class="box-item-col mt-1">
                    <span class="info-text d-inline-block" translate>Price</span>
                    <div class="gap-8 d-inline-flex align-items-center">
                        <span class="price-value mb-0"
                            [ngClass]="{'empty-price': !product?.price}">{{product?.price
                            ? product?.currency?.symbol : null}}{{product?.price ?
                            (product?.price | numberSuffix: 2) : '-'}} </span>
                        <span class="delta-content" *ngIf="product?.price_change"
                            [ngClass]="{'negative':product?.price_change>0}"><i
                                class="icon icon-Triangle-Down1"></i>{{product?.currency?.symbol}}{{(commonFunctions.getAbsoluteValue(product?.price_change)
                            | numberSuffix: 2)}}
                            {{product?.price_change>0?("Higher" | translate):("Lower" | translate)}}</span>
                    </div>
                </div>
                <div class="box-item-col desc">
                    <span class="info-text d-inline-block" translate>Description</span>
                        <div class="desc cursor-pointer" (click)="descriptionReadMore()" *ngIf="product?.description">
                            <ng-container *ngIf="product?.isExplicitDescription;else showNormalDescription">
                                <p>View Explicit Description</p>
                            </ng-container>
                            <ng-template #showNormalDescription>
                                {{product?.description | slice:0:95}}<span>...</span><span>Read More</span>
                            </ng-template>
                        </div>
                        <div class="desc" *ngIf="!product?.description">-</div>
                </div>
                <div class="status box-item-col">
                    <span class="info-text d-inline-block" translate>Status</span>
                    <div class="labels d-flex" *ngIf="product?.tags && product?.tags?.length>0">
                        <label *ngFor="let tag of product?.tags" class="badge badge-dark">{{tag}}</label>
                    </div>
                    <div class="labels d-flex" *ngIf="!product?.tags || product?.tags?.length==0">-</div>
                </div>
                <div class="box-item-col"> <span class="info-text d-inline-block" translate>Category</span>
                    <span showTooltipOnTextOverflow [data]="product?.category"
                        class="box-value-text"></span>
                </div>
                <div class="box-item-col align-items-center"> <span class="info-text d-inline-block"
                        translate>Channel
                    </span>
                    <div class="d-flex align-items-center">
                        <app-marketplace-store-logo [storeName]="product?.marketplace">
                        </app-marketplace-store-logo>
                        <span class="marketplace-name" showTooltipOnTextOverflow
                            [data]="product?.marketplace"></span>
                    </div>
                </div>
                <div class="box-item-col">
                    <span class="info-text d-inline-block" translate>Store Name
                    </span>
                    <span showTooltipOnTextOverflow [data]="product?.store" class="box-value-text"></span>
                </div>
                <div class="box-item-col">
                    <span class="info-text d-inline-flex align-items-center w-50" translate>Likes</span>
                    <span *ngIf="product?.likes"
                        class="d-inline-block price-value">{{product?.likes | numberSuffix : 2}}</span>
                    <span *ngIf="!product?.likes"
                        class="box-value-text voucher-null d-inline-flex align-items-center">-</span>
                </div>
                <div class="box-item-col">
                    <span class="info-text d-inline-flex align-items-center w-50" translate>Discount</span>
                    <span *ngIf="product?.discount"
                        class="voucher-badge d-inline-block">{{product?.discount}}% {{'OFF' | translate}}</span>
                    <span *ngIf="!product?.discount"
                        class="box-value-text voucher-null d-inline-flex align-items-center">-</span>
                </div>
                <div class="box-item-col">
                    <span class="info-text d-inline-flex align-items-center w-50" translate>Vouchers</span>
                    <ng-template [ngIf]="product?.vouchers && product?.vouchers?.length > 0">
                        <span class="box-value-text" *ngFor="let promo of product?.vouchers">
                            <span class="voucher-badge">
                                {{promo}}
                            </span>
                        </span>
                    </ng-template>
                    <span class="box-value-text voucher-null d-inline-flex align-items-center"
                        *ngIf="!product?.vouchers || product?.vouchers?.length == 0">
                        <span>
                            -
                        </span>
                    </span>
                </div>
                <div class="box-item-col">
                    <span class="info-text d-inline-flex align-items-center w-50" translate>Trackers</span>
                    <div ngbDropdown #profilePopper="ngbDropdown">
                        <div ngbDropdownToggle id="priceRangePopup" class="user-profile">
                            <span class="profileCount">{{product.tracked_by?.length}}<span class="clickProfile"
                                    [ngStyle]="{'color':(profilePopper.isOpen())?'#ff5e00':''}"> (Click to see trackings)</span></span>
                        </div>
                        <div ngbDropdownMenu class="profile-pop" aria-labelledby="priceRangePopup">
                            <section class="profiles-list-container">
                                <ng-container *ngFor="let profile of product.tracked_by;let i=index">
                                    <section class="profile-content d-flex gap-20 justify-content-between align-items-center" [ngStyle]="{'backgroundColor':profile.is_customer?'#fff5f1':'#f6f9fb'}">
                                        <span class="profile-name">{{profile.profile_name}}</span>
                                        <span class="profile-type" [ngStyle]="{'backgroundColor':profile.is_customer?'#ede7e6':'#ededef'}">{{profile.profile_type}}</span>
                                    </section>
                                </ng-container>
                            </section>
                        </div>
                    </div>
                    <span class="box-value-text" *ngIf="!product.tracked_by?.length">
                        <span>
                            -
                        </span>
                    </span>
                </div>
                <div class="box-item-col pt-0">
                    <span class="info-text d-inline-block" translate>Last Updated</span>
                    <span class="box-value-text">
                        {{commonFunctions.formatSimpleDate(product?.updated_at)}}
                    </span>
                </div>
            </div>
        </div>
        <ng-template #loader>
            <div *ngIf='!product' class="no-data-common">
                <div *ngIf="!isLoading" class="no-data-found" translate>
                    <div class="img"><i class="icon icon-Search"></i></div>
                    <div class="no-data-text" translate>No data present</div>
                </div>
                <div *ngIf="isLoading" class="loading-panel">
                    <img src="/assets/images/spinner.svg" alt="">
                </div>
            </div>
        </ng-template>
    
    </div>
</div>
</div>